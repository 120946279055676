#search {
    padding: 8px; 
    margin-right: 8px; 
    border: 1px solid #ccc; 
    border-radius: 1px; 
    /* width: 25rem; */
  }
  
  #search::placeholder {
    font-weight: 400;
  }
  
  #search:focus {
    outline: #E14E1D; 
    
  }


  .fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 50;
  }
  
  .bg-opacity-50 {
    background-color: rgba(0, 0, 0, 0.5);
  }