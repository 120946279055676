body {
  background: #fffbf2;
}

* {
  padding: 0%;
  margin: 0%;
  font-family: "Lora", sans-serif;
  box-sizing: border-box;
}
